<template>

  <!-- TODO: Uncomment code and remove MyApps and RecentRegistrationsWidget when Portal is ready -->


  <!-- <div class="section">
    <div class="level">
      <div class="level-right">
        <a :href="portalUrl" id="landingPageLogo">
          <img src="https://cdn.allegromicro.com/logos/aml_tagline_80h.png" alt="Allegro MicroSystems" />
        </a>
      </div>
      <div class="level-left">
        <div>
          <h1 class="title has-text-primary">Registrations</h1>
        </div>
      </div>
    </div>
  </div> -->

        <div class="mt-3">

          <MyApps></MyApps>
          <RecentRegistrationsWidget @showDraftsDialog="showDraftsDialog = true"></RecentRegistrationsWidget>
          
                <!-- <div class="column">
          <home-menu-item
            v-if="userAuth.canCreateRegistration"
            title="Create Registration"
            subtitle="Create a new registration"
            url="/registrations/create"
            icon="fas fa-plus-circle"
          >
          </home-menu-item>
          
          <home-menu-item
            v-if="userAuth.canCreateRegistration"
            title="Complete draft registration"
            subtitle="Complete or update a draft registration"
            icon="fas fa-edit"
            url="#"
            @click="showDraftsDialog = true"
          >
          </home-menu-item>
          <home-menu-item
            title="Search Registrations"
            subtitle="Search registrations and see details"
            url="/search"
            icon="fas fa-search"
          >
          </home-menu-item>
          <home-menu-item
            v-if="userAuth.canViewIncentivePrograms"
            title="Incentive Programs"
            subtitle="Add or modify incentive programs"
            url="/incentivePrograms"
            icon="fas fa-chart-line"
          >
          </home-menu-item>
          
          <home-menu-item
            v-if="userAuth.canViewDistributorSettings"
            title="Distributor Settings"
            subtitle="Add or modify distributor settings"
            url="/distributorSettings"
            icon="fas fa-user-cog"
          >
          </home-menu-item>
                </div> -->
        </div>
      

  <DraftsDialog :isVisible="showDraftsDialog" @done="showDraftsDialog = false"></DraftsDialog>
</template>

<script>
import { DraftsDialog, RecentRegistrationsWidget, MyApps } from "@/components";

export default {
  components: {
    DraftsDialog,
    RecentRegistrationsWidget,
    MyApps
  },
  inject: ["userAuth"],
  data() {
    return {
      showDraftsDialog: false,
      portalUrl: process.env.VUE_APP_PORTAL_URL
    };
  },
};
</script>

<style>
/* #home-page #navbarLogo {
  display: none;
}

@media screen and (min-width: 1024px) {
  #home-page .navbar-menu > .navbar-start {
    display: none !important;
  }
} */
</style>

<style scoped>
/* .section + .section {
  padding-top: 1.5rem;
  margin-top: 0;
} */

.level {
  flex-direction: row-reverse;
}
</style>
